import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css';
import _ from 'lodash';
import './SelectWithExtraAndSearch.scss';
class SelectWithExtraAndSearch extends Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.items.length === 0) {
      this.props.getResources();
    } else {
      // this.props.input.onChange(this.props.items[0].objectId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const show = _.find(nextProps.items, {value: nextProps.input.value});
    //console.log('---------->check', show, nextProps.input.value, this.props.items)
    this.props.showExtraField(show && show.hasExtraInput);
  }

  handleChange(value) {
    this.props.input.onChange(value);
  }

  render() {
    let { meta: {touched, error}, label } = this.props;
    return (
      <div className={error ? 
        'error-form custom-input-container select-with-search' : 
        ' custom-input-container select-with-search'}> 
        <span className="subtitle">{label}</span>
        <SelectSearch 
          search={true} 
          options={this.props.items} 
          value={this.props.input.value} 
          onChange={this.handleChange} 
          placeholder="大学名を入力して選択。"
        />
        <p className="suggestText">※ヒットしない場合、『その他』を選択</p>
      </div>
    );
  }
}

SelectWithExtraAndSearch.defaultProps = {
  getResources: () => {}
};

SelectWithExtraAndSearch.propTypes = {
  getResources: PropTypes.func,
  items: PropTypes.array,
  input: PropTypes.object,
  showExtraField: PropTypes.func
};

export default SelectWithExtraAndSearch;
