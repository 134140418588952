import React, { Component, Fragment } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import moment from 'moment';
import 'moment/locale/ja';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import {
  setMobileMessageView,
  requestEventDetailForNewConversation
} from 'containers/Message/actions';
import Loading from 'components/Loading';
import { timeDisplay } from '../../../../utilities/timeFormat';
import PlatinumLabel from '../../../../components/PlatinumLabel';
class MessageCell extends Component {
  locationSection = () => {
    return (
      <div>
        <div className="align-items-center">
          <img alt="" src="/images/location2.svg" />
          <div className="location">{this.props.item.location}</div>
        </div>
      </div>
    );
  };

  renderSubContent = () => {
    const { item, currentUser } = this.props;
    const isSpecialEvent = _get(item, 'offerEvent', false) || _get(item, 'eventType') === 'GOCHI_EVENT';
    if (currentUser) {
      if (isSpecialEvent) {
        return (
          <div className="company-name">{_get(item, 'lastMessage', '')}</div>
        );
      }

      if (currentUser.role === 'PRESIDENT') return this.locationSection();
      return (
        <div className="align-items-center">
          <img alt="" src="/images/group.svg" />
          <div className="company-name">
            {item.jCreatedBy.presidentProfile &&
              item.jCreatedBy.presidentProfile.companyName}
          </div>
        </div>
      );
    }
  };

  render() {
    const { item, currentUser } = this.props;
    const timeAgo = timeDisplay(item.lastActionTime);
    if (!item.jCreatedBy) {
      this.props.requestEventDetailForNewConversation(
        item.objectId || this.props.eventId
      );
      return <Loading />;
    }

    const status = _get(item, 'eventStateInfo.status', '');
    let statusText = _get(item, 'eventStateInfo.statusText', '');
    let statusClass = '';
    if (status === 'LOCKED') {
      statusClass = 'status locked';
    }
    if (status === 'REQUESTED' || status === 'WANTED') {
      statusClass = 'status wanted';
    }
    if (
      status === 'EVENT_FINISHED' ||
      status === 'WANTED_EXIT' ||
      status === 'EVENT_CANCELED'
    ) {
      statusClass = 'status finished';
    }

    let squaredAvaClass = '';
    let thumbnail = item.jCreatedBy.avatarImage
      ? item.jCreatedBy.avatarImage.thumbSmall
      : '/images/default-avatar.png';
    let cellName = item.jCreatedBy.displayName + '　社長';
    const isSpecialEvent = _get(item, 'offerEvent', false) || _get(item, 'eventType') === 'GOCHI_EVENT';

    if (currentUser && currentUser.role === 'PRESIDENT') {
      squaredAvaClass = isSpecialEvent ? '' : 'ava-square';
      thumbnail = item.jImage && item.jImage.thumbSmall;
      cellName = item.eventTitle;
    }

    if (currentUser && (_get(item, 'offerEvent', false) || _get(item, 'eventType') === 'GOCHI_EVENT')) {
      if (_get(item, 'eventType') === 'GOCHI_EVENT') {
        statusText = 'ごちリクエスト承諾';
      }
      if (_get(item, 'offerEvent', false)) {
        statusText = 'オファー承諾';
      }
      statusClass = 'status finished';
      if (currentUser.role === 'PRESIDENT') {
        cellName = `${_get(item, 'student.displayName', item.eventTitle)} ${
          _get(item, 'student.studentProfile.university', '')
            ? `(${item.student.studentProfile.university}${_get(item, 'student.studentProfile.graduationYear', '') 
              ? `、${item.student.studentProfile.graduationYear}年卒` : ''})`
            : ''
        }`;
      }
      if (currentUser.role === 'STUDENT') {
        cellName = `${_get(item, 'jCreatedBy.displayName', item.eventTitle)} ${
          _get(item, 'jCreatedBy.presidentProfile.companyName', '')
            ? `(${item.jCreatedBy.presidentProfile.companyName})`
            : ''
        }`;
      }
    }

    return (
      <Fragment>
        <div
          className={`msg-noti-cell info ${item.unreadMessage > 0 &&
            'unread-bg'} ${this.props.active && 'active-bg'}`}
          onClick={this.props.turnToMessageView}
        >
          <div className='cell-info'>

            <Link
              to={{
                pathname: `/${
                  item.offerEvent === false ? 'event-detail' : 'studentDetail'
                }/${
                  item.offerEvent === false ? item.objectId : item.joinedUserIds
                }`,
                state: {
                  eventId: item.objectId
                }
              }}
              className={`avatar ${squaredAvaClass}`}
            >
              <img alt="" src={thumbnail} />

              {item.unreadMessage > 0 && <div className="unread-point" />}
            </Link>

            <Link
              to={{
                pathname: `/messages/${item.objectId}`,
                state: {
                  eventId: item.objectId
                }
              }}
              className="president-info"
            >
              <div className="name">{cellName}</div>

              {this.renderSubContent()}
              <div className="event-info">
                {!isSpecialEvent && (
                <>
                  <div>
                    <div className="align-items-center">
                      <img alt="" src="/images/calendar2.svg" />
                      <div>{moment(item.eventDate).format('MM/DD (ddd)')}</div>
                    </div>
                  </div>
                  <div>
                    <div className="align-items-center">
                      <img alt="" src="/images/clock.svg" />
                      <div>{moment(item.eventDate).format('HH:mm')}</div>
                    </div>
                  </div>
                </>
                )}
                {currentUser && currentUser.role === 'PRESIDENT'
                  ? null
                  : !isSpecialEvent && this.locationSection()}
              </div>
            </Link>

            <div className="moment">{timeAgo}</div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className={statusClass}>
              <div>{statusText}</div>
            </div>
            {item.priority === '1' && <PlatinumLabel className='size-sm' style={{ margin: '8px 0 0 8px' }} />}
          </div>
        </div>
        <hr />
      </Fragment>
    );
  }
}

MessageCell.propTypes = {
  item: PropTypes.object,
  eventId: PropTypes.string,
  turnToMessageView: PropTypes.func,
  active: PropTypes.bool,
  requestEventDetailForNewConversation: PropTypes.func,
  currentUser: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.get('account').get('data')
  };
};

const mapDispatchToProps = (dispatch) => ({
  turnToMessageView: () => dispatch(setMobileMessageView(1)),
  requestEventDetailForNewConversation: (eventId) =>
    dispatch(requestEventDetailForNewConversation(eventId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(MessageCell));
