import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

import ManagementFilter from './ManagementFilter';
import MagnementMenu from '../ManagementMenu';
import FollowList from './FollowList';
import EventList from './EventList';
import { StudentManagementMenu } from '../../../utilities/constants';
import UniversitySelectorModal from 'containers/UniversitySelectorModal';
import Plans from 'containers/Plans';
import AvailablePlan from 'containers/Plans/AvailablePlan';
import './style.scss';
import PopupSelectTemplate from '../../PopupSelectTemplate';
import SearchStudents from './SearchStudents';

class StudentsManagement extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { location } = this.props;

    return (
      <div className='students-management'>
        <div className='students-management-container'>
          <div className='students-management-controller'>
            <div className='students-management-title'>
              ユーザー管理
            </div>

            <div className='students-management-menu-items'>
              <MagnementMenu
                ManagementMenu={StudentManagementMenu}
                history={this.props.history}
                location={this.props.location}
              />
            </div>

            {[
              '/management-student/students-of-event',
              '/management-student/events-of-student',
              '/management-student/search'
            ].includes(location.pathname)
              && (
                <ManagementFilter className='mobile-management-filter' />
              )}

            {[
              '/management-student',
              '/management-student/search',
              '/management-student/offer'
            ].includes(location.pathname)
              && (
                <Plans>
                  <AvailablePlan />
                </Plans>
              )}
          </div>

          <div className='students-management-list'>
            <Switch>
              <Route exact path='/management-student' component={FollowList} />
              <Route exact path='/management-student/students-of-event' component={EventList} />
              <Route exact path='/management-student/events-of-student' component={EventList} />
              <Route exact path='/management-student/search' component={SearchStudents} />
              <Route exact path='/management-student/offer' component={FollowList} />
              <Redirect to='/404-not-found' />
            </Switch>
          </div>
        </div>
        <PopupSelectTemplate />
        <UniversitySelectorModal />
      </div>
    );
  }
}

StudentsManagement.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default withImmutablePropsToJS(StudentsManagement);