import { request } from './../../utilities/Api';
import { put, call, select } from 'redux-saga/effects';
import { takeFirst } from 'utilities/supportSaga';
import { receiveNewEvents, FETCH_NEW_EVENTS_REQUEST, failToGetNewEvents } from './actions';
import { getNewEventsState } from './selectors';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

function* getNewEvents(action) {
  try {
    const newEvents = yield select(getNewEventsState);
    let page = newEvents.get('page');
    let hasMore = newEvents.get('hasMore');
    const lastCreatedAt = newEvents.get('lastCreatedAt');
    if (!hasMore) {
      yield put(failToGetNewEvents({hasMore}));
      return;
    }
    
    yield put(showLoading());
    if (action.more) {
      ++page;
    } else {
      page = 1;
    }
    const data = yield call(request, 'functions/getEventList', {limit: 12, page: page, lastCreatedAt}, 'POST');
    yield put(receiveNewEvents(data.result.eventList, page));
    yield put(hideLoading());
  } catch (error) {
    console.log(error);
    yield put(hideLoading());
  }
}


export default function* watchNewEvents() {
  yield takeFirst(FETCH_NEW_EVENTS_REQUEST, getNewEvents);
}