import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { reduxForm, Field, formValueSelector, Fields, FieldArray } from 'redux-form/immutable';
import EventPhoto from 'containers/CreateEvent/EventPhoto';
//import validate from './validate';
import { Datepicker, Input, Select, InputLocation, NewDescription } from './../../../components/Form';
import validate from './validate';
import { getResources } from 'containers/Resources/actions';
import './style.scss';
import moment from 'moment';
import MapPinMarker from '../../../components/MapPinMarker';
import PropTypes from 'prop-types';

class CreateEventForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: undefined,
      isOpenMapModal: false
    };
  }

  openMapModal = () => {
    this.setState({
      isOpenMapModal: true
    });
  }

  closeMapModal = () => {
    this.setState({
      isOpenMapModal: false
    });
  }

  async componentDidMount() {
    // if(!this.props.prefecture || !this.props.graduateYear)
    await this.props.getResources(['Prefecture', 'graduateyear']);
  }

  render() {
    const { pristine, valid, handleSubmit, location = '', isEdit = false } = this.props;
    const graduateYear = this.props.graduateYear || [];
    const perfecture = this.props.prefecture || [];
    const capacity = [5, 6, 7, 8, 9, 10];
    return (
      <form onSubmit={handleSubmit}>
        <div className="create-event-field">
          <Field
            name="eventDate"
            dateFormat="YYYY-MM-DD"
            label="日にち"
            disabled={isEdit}
            component={Datepicker}
            customForm={true}
            required={true}
            minDate={moment(new Date()).add(4, 'days').toDate()}
            maxDate={moment(new Date()).add(1, 'years').toDate()}
          />
        </div>
        <div className="create-event-field">
          <Field
            name="eventTime"
            label="開始時間"
            customForm={true}
            required={true}
            component={Datepicker}
            disabled={isEdit}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            minTime={moment(new Date()).set('hour', 7).set('minute', 0).toDate()}
            maxTime={moment(new Date()).set('hour', 22).set('minute', 0).toDate()}
          />
        </div>

        <div className="create-event-field">
          <Field
            name="duration"
            label="開催時間"
            component={Select}
            customForm={true}
            required={true}
          >
            <option value="60">60 分</option>
            <option value="90">90 分</option>
            <option value="120">120 分</option>
            <option value="180">180 分</option>
            <option value="240">240 分</option>
            <option value="300">300 分</option>
          </Field>
        </div>

        <div className="create-event-field">
          <Field
            name="name"
            type="text"
            label="イベント名"
            placeholder="イベント名"
            component={Input}
            customForm={true}
            maxLength={100}
          />
        </div>
        <div className="create-event-field h-128">
          <div className="create-event-photo-container">
            <div className="create-event-photo">
              <Field
                name="image"
                component={EventPhoto}
                customForm={true}
              />
            </div>
            <div className="create-event-text">
              <div className="create-event-up">写真を追加</div>
              <div className="create-event-down">写真を入れなければ、あなたのアイコンがイベントTOPに表示されます。</div>
            </div>
          </div>
        </div>
        <div className="create-event-field">
          <FieldArray name="newDescription" component={NewDescription}/>
        </div>
        <div className="create-event-field">
          <Field
            name="location"
            type="text"
            label="場所を入力"
            placeholder="場所を入力"
            disabled={isEdit}
            component={InputLocation}
            openMapModal={this.openMapModal}
            customForm={true}
            maxLength={100}
          />
        </div>
        <div className="create-event-field ">
          <Field
            name="capacity"
            type="number"
            label="定員数"
            component={Select}
            placeholder="定員数"
            customForm={true}
          >{capacity.map((item) => {
              return <option value={item} key={item}>{item} 人</option>;
            })}
          </Field>
        </div>
        <div className="create-event-field">
          <Field
            name="graduateYear"
            type="text"
            label="対象卒業年"
            placeholder="対象卒業年"
            component={Select}
            customForm={true}
          ><option disabled key='null' hidden></option>
            {graduateYear.map((item) => {
              return <option value={`${item.objectId}|${item.name}`} key={item.name}>{item.name}</option>;
            })}
          </Field>
        </div>
        <div className="create-event-field">
          <Field
            name="prefecture"
            type="text"
            label="開催地域"
            placeholder="開催地域"
            component={Select}
            customForm={true}
          ><option disabled key='null' hidden></option>
            {perfecture.map((item) => {
              return <option value={`${item.objectId}|${item.name}`} key={item.name}>{item.name}</option>;
            })}
          </Field>
        </div>
        <div className="action-container">
          <button
            className={(!valid || pristine) ? 'btn btn-cancel' : 'btn btn-submit'}
            disabled={!this.props.valid || this.props.pristine}
            name="submit"
            type="submit"
          >
            {isEdit ? '編集' : 'プレビューを見る'}
          </button>
        </div>
        <Fields
          names={['longitude', 'latitude']}
          component={MapPinMarker}
          isOpenMapModal={this.state.isOpenMapModal}
          closeMapModal={this.closeMapModal}
          location={location}
          changeLocation={this.props.change}
          locationSearchable={this.state.locationSearchable}
          changeLocationSearchable={this.changeLocationSearchable}
        />
      </form>
    );
  }
}

CreateEventForm.propTypes = {
  pristine: PropTypes.any,
  valid: PropTypes.any,
  handleSubmit: PropTypes.func,
  location: PropTypes.string,
  getResources: PropTypes.func,
  graduateYear: PropTypes.array,
  prefecture: PropTypes.any,
  change: PropTypes.func,
  isEdit: PropTypes.bool
};
const mapStateToProps = (state) => ({
  graduateYear: state.get('resources').get('graduateyear'),
  prefecture: state.get('resources').get('prefecture'),
  location: formValueSelector('createEvent')(state, 'location')
});
const mapDispatchToProps = dispatch => ({
  getResources: (resourcenames) => dispatch(getResources(resourcenames))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'createEvent',
    validate,
    destroyOnUnmount: false,
    enableReinitialize: false

  }),
  withImmutablePropsToJS
)(CreateEventForm);