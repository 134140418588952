import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { FETCH_RESOURCES_REQUEST, receiveResources, fecthResourcesFailure, setLoading } from './actions';

function* getResourceFromServer(action) {
  try {
    yield put(setLoading(true));
    const data = yield call(request, 'functions/getResourceList', {resourceList: action.resources}, 'POST');
    yield put(receiveResources(data.result));
  } catch (e) {
    yield put(fecthResourcesFailure('fetch data failure'));
  }
}

export default function* watchResources() {
  yield takeEvery(FETCH_RESOURCES_REQUEST, getResourceFromServer);
}

 