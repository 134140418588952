import React, {Component} from 'react';
import {connect} from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import { studentSurvey } from './actions';
//Component
import StudentSurveyForm from './StudentSurveyForm';
//Style
import './style.scss';

class StudentSurvey extends Component {

  handleSubmit = (values) => {
    const data = values.toJS();
    this.props.submitSurvey(data);
  }

  render() {
    return (
      <StudentSurveyForm onSubmit={this.handleSubmit} />
    );
  }
}

StudentSurvey.propTypes = {
  show: PropTypes.bool,
  setPopupWelcome: PropTypes.func,
  getResources: PropTypes.func,
  answerList: PropTypes.array,
  answer: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  submitSurvey: (values) => dispatch(studentSurvey(values)),
});


export default compose(
  connect(null, mapDispatchToProps),
  withImmutablePropsToJS
)(StudentSurvey);


