import isAsciiEmail from 'sane-email-validation';

function telephoneCheck(str) {
  var isphone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(str);
  return !!isphone;
}

const validate = values => {
  const errors = {};
  if (!values.has('fullname')) {
    errors.fullname = 'この値は必須です。';
  }
  if (!values.has('companyName')) {
    errors.companyName = 'この値は必須です。';
  }
  if (!values.has('email')) {
    errors.email = 'この値は必須です。';
  } else if (!isAsciiEmail(values.get('email'))) {
    errors.email = 'Invalid email address';
  }
  if (!values.has('password')) {
    errors.password = 'この値は必須です。';
  }
  if (!values.has('confirmPassword')) {
    errors.confirmPassword = 'この値は必須です。';
  } else if (values.has('password') && values.get('password') !== values.get('confirmPassword')) {
    errors.confirmPassword = 'Please enter the same password as above';
  }

  if (!values.has('phone')) {
    errors.phone = 'この値は必須です。';
  } else if (!telephoneCheck(values.get('phone'))) {
    errors.phone = 'Invalid Phone Number';
  }

  return errors;
};

export default validate;
