import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { STUDENT_COMPLETE_PROFILE, studentCompleteProfileSuccess, studentCompleteProfileFail} from './actions';
import { getCurrentAccount } from './../Account/actions';
import { stopSubmit } from 'redux-form';

function* handleStudentCompleteProfile(action) {
  const { meta, message } = action; 
  try {
    if (action.isRequestJoinEvent) {
      yield call(request, 'functions/updateUserInformation', action.values, 'POST', {
        headers: { 'X-Parse-Session-Token': action.sessionToken }
      });
      //yield put(getCurrentAccount(action.sessionToken, 'STUDENT_COMPLETE_PROFILE'));
    } else {
      yield call(request, 'functions/updateUserInformation', action.values, 'POST', {
        headers: { 'X-Parse-Session-Token': action.sessionToken }
      });
      yield put(getCurrentAccount(action.sessionToken, message || 'STUDENT_COMPLETE_PROFILE'));
      yield put(studentCompleteProfileSuccess(meta));
    }
  } catch (e) {
    yield put(stopSubmit('StudentProfileForm', { _error: e.error }));
    yield put(studentCompleteProfileFail(meta));
  }
}

export default function* watchStudentCompleteProfile() {
  yield takeEvery(STUDENT_COMPLETE_PROFILE, handleStudentCompleteProfile);
}

 