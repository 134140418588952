import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import { FOLLOWER_TYPE } from 'utilities/constants';
import Button from 'components/Button';
import PresidentDetailCarousel from '../../../components/PresidentDetailCarousel';

export default class StudentProfile extends Component {
  constructor(props) {
    super(props);

    // For automatically scrolling to top after clicking on next or previous button on popup in event detail
    this.popupPosition = React.createRef();
  }

  componentDidUpdate(nextProps) {
    if (!_.isEqual(this.props.objectId, nextProps.objectId)) {
      this.popupPosition.current.scrollTop = 0;
    }
  }

  renderGochiButton = () => {
    const { gochiStatus = {}, acceptGochiRequest } = this.props;
    if (_.get(gochiStatus, 'type', '') === FOLLOWER_TYPE.GOCHI) {
      return (
        <Button
          disabled={_.get(gochiStatus, 'status', '') === 'ACCEPTED'}
          className="student-details__gochi--accept"
          onClick={() => {
            if (acceptGochiRequest) {
              acceptGochiRequest();
            }
          }}
        >
          この学生に会いたい
        </Button>
      );
    }
    return null;
  };

  renderInfoDetail = (currentUser) => {
    const { objectId } = this.props;
    if (_.isEmpty(objectId)) {
      return (
        <>
          <div className="student-details__row--overflow">
            <div className="student-details__column">
              <p className="student-details__label student-details__label--spacing">
                電話番号
              </p>
              <p className="student-details__detail student-details__detail--spacing">
                {currentUser.phone}
              </p>
            </div>
            <div className="student-details__column">
              <p className="student-details__label student-details__label--spacing">
                メールアドレス
              </p>
              <p className="student-details__detail student-details__detail--spacing">
                {currentUser.email}
              </p>
            </div>
          </div>
          <div className="student-details__event--table">
            <div className="student-details__event--table-row">
              <div className="student-details__event--table-col">応募数</div>
              <div className="student-details__event--table-col">確定数</div>
              <div className="student-details__event--table-col">欠席数</div>
            </div>
            <div className="student-details__event--table-row">
              <div className="student-details__event--table-col">
                {_.get(currentUser, 'totalRequestedQuantity', 0)}
              </div>
              <div className="student-details__event--table-col">
                {_.get(currentUser, 'totalAttendence', 0)}
              </div>
              <div className="student-details__event--table-col">
                {_.get(currentUser, 'totalAbsent', 0)}
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="student-details__row--overflow">
          <div className="student-details__column">
            <p className="student-details__label student-details__label--spacing">
              電話番号
            </p>
            <p className="student-details__detail student-details__detail--spacing">
              {currentUser.phone}
            </p>
          </div>
          <div className="student-details__column">
            <p className="student-details__label student-details__label--spacing">
              メールアドレス
            </p>
            <p className="student-details__detail student-details__detail--spacing">
              {currentUser.email}
            </p>
          </div>
        </div>
        {this.renderGochiButton()}
      </>
    );
  };

  renderStudentInfo = (currentUser, isJoinedStudent) => {
    const { objectId, eventId, studentDetail } = this.props;
    const yearsOld = moment().diff(moment(currentUser.birthday), 'years');
    const userYearsOld = currentUser.birthday ? ' (' + yearsOld + ')' : '';
    /*
      Show name, year old, phone, email
      _.isEmpty(objectId): My Page (Student role)
      isJoinedStudent: Event Detail - Joined Student
      _.isEmpty(eventId): Notification, Chat, Access by link
      eventId === 'undefined': Follow
    */
    if (
      _.isEmpty(objectId) ||
      isJoinedStudent ||
      _.isEmpty(eventId) || eventId === 'undefined'
    ) {
      if (
        !_.isEmpty(objectId) && (_.isEmpty(eventId) || eventId === 'undefined') &&
        !_.get(studentDetail, 'hasJoinEvent', false)
      ) {
        return (
          <div className="student-details__column__gochi">
            {this.renderGochiButton()}
          </div>
        );
      }

      return (
        <div className="student-details__column__info">
          <div className="student-details__label--name student-details__label--spacing">
            <span>{currentUser.fullname}</span>
            <span id="student-details__old">{userYearsOld}</span>
            {yearsOld < 20 && (
              <img alt="" id="bidden-beer" src="/images/bidden-beer.svg" />
            )}
          </div>
          <p className="student-details__detail">{currentUser.nameHiragana}</p>
          {this.renderInfoDetail(currentUser)}
        </div>
      );
    }
  };

  render() {
    /**
     * @property {object} eventInfo For showing or hiding some student's information
     * @property {object} eventId For showing or hiding some student's information
     * @property {bool} isInPopup Move reason next to avatar in popup toward Requested Student & Denied Student in Event Detail
     */
    const {
      eventInfo = {},
      studentDetail,
      objectId,
      eventId,
      isInPopup
    } = this.props;
    const {
      requestedUsers = [],
      joinedUsers = [],
      deniedUsers = []
    } = eventInfo;

    // Initial student's object contains information & reason when requesting to join event
    let student = {};
    // For showing or hiding some student's information
    let isJoinedStudent = false;

    /**
     * President role
     * @case Event Detail (Requested Student, Joined Student, Denied Student) Popup
     * @case Student Management (Denied Student) Page
     */
    if (eventId && !_.isEmpty(eventInfo)) {
      if (!_.isEmpty(requestedUsers)) {
        student = _.find(requestedUsers, { objectId: objectId });
      }

      if (!_.isEmpty(joinedUsers) && _.isEmpty(student)) {
        student = _.find(joinedUsers, { objectId: objectId });

        if (student) {
          isJoinedStudent = true;
        }
      }

      if (!_.isEmpty(deniedUsers) && _.isEmpty(student)) {
        student = _.find(deniedUsers, { objectId: objectId });
      }

      if (_.isEmpty(student)) {
        return <Redirect to={`/event-detail/${eventId}`} />;
      }
    }

    let currentUser = (objectId ? studentDetail : this.props.currentUser) || {};
    const studentProfile = currentUser.studentProfile || {};

    if (
      studentDetail.followingMe === false &&
      studentDetail.hasJoinEvent === false &&
      !eventId &&
      objectId
    ) {
      return <Redirect to="/" />;
    }

    let hasPersonalInfo = '';

    // For all case except Requested Student & Denied Student in Event Detail
    if (isJoinedStudent || !eventId || _.isEmpty(eventInfo) || !isInPopup) {
      hasPersonalInfo = 'hasPersonalInfo';
    }

    return (
      <div id="student-details__container">
        <section className="student-details__frame" />
        <section className="student-details__wrapper" ref={this.popupPosition}>
          <div className="student-details__row">
            {/* Carousel */}
            <div className="student-details__column__avatar">
              <div className="col-square">
                <PresidentDetailCarousel images={currentUser.photos} />
              </div>
            </div>
            {_.isEmpty(objectId) && (
              <div className="student-details__column__button">
                <div className="student-details__button--edit">
                  <Link to="/edit-my-page">
                    <button>
                      <img alt="" src="/images/edit.svg" />
                      編集
                    </button>
                  </Link>
                </div>
              </div>
            )}
            {this.renderStudentInfo(currentUser, isJoinedStudent)}

            {/*
              Show reason when requesting to join event

              @case Event Detail (Requested Student, Joined Student, Denied Student) Popup
              @case Student Management (Denied Student) Page

              hasPersonalInfo: If not have this class name,
                move reason next to avatar in popup toward Requested Student & Denied Student
            */}
            <div className={`student-details__column__info ${hasPersonalInfo}`}>
              {student && student.reason && (
                <div className="reason-container">
                  <p className="student-details__label student-details__label--spacing">
                    応募時のメッセージ
                  </p>
                  <div className="reason">
                    <img alt="" src="/images/requested-user-reason.svg" />
                    {student.reason}
                  </div>
                </div>
              )}

              <span className="student-details__tag">
                <p className="student-details__label student-details__label--spacing">
                  志望業界
                </p>
                {studentProfile.companyTags &&
                  studentProfile.companyTags.map((tag, index) => (
                    <span key={index}>{tag}</span>
                  ))}
              </span>
            </div>
          </div>

          <div className="student-details__info pre-line">
            {/* PR  */}
            <p className="student-details__label student-details__label--spacing">
              自己PR
            </p>
            <p className="student-details__detail student-details__detail--spacing">
              {studentProfile.introduction}
            </p>

            <p className="student-details__label student-details__label--spacing">
              性別
            </p>
            <p className="student-details__detail student-details__detail--spacing">
              {currentUser.gender === 'MALE'
                ? '男性'
                : currentUser.gender === 'FEMALE'
                  ? '女性'
                  : 'どちらでもない'}
            </p>

            <span className="student-details__tag">
              <p className="student-details__label student-details__label--spacing">
                タグ
              </p>
              {currentUser.tags &&
                currentUser.tags.map((tag, index) => (
                  <span key={index}>{tag}</span>
                ))}
            </span>
          </div>
          <hr />
          {/* Student info */}
          <div className="student-details__row--default pre-line">
            <div className="student-details__column">
              <p className="student-details__label student-details__label--spacing">
                研究室・ゼミ
              </p>
              <p className="student-details__detail student-details__detail--spacing">
                {studentProfile.seminar}
              </p>

              <p className="student-details__label student-details__label--spacing">
                出身高校名(任意)
              </p>
              <p className="student-details__detail student-details__detail--spacing">
                {studentProfile.highSchool}
              </p>

              <p className="student-details__label student-details__label--spacing">
                部活・サークル
              </p>
              <p className="student-details__detail student-details__detail--spacing">
                {studentProfile.club}
              </p>

              <p className="student-details__label student-details__label--spacing">
                ポリシー・こだわり
              </p>
              <p className="student-details__detail student-details__detail--spacing">
                {studentProfile.yourPolicy}
              </p>

              <p className="student-details__label student-details__label--spacing">
                将来の目標
              </p>
              <p className="student-details__detail student-details__detail--spacing">
                {studentProfile.yourDream}
              </p>
              <p className="student-details__label student-details__label--spacing">
              出身地
              </p>
              <p className="student-details__detail student-details__detail--spacing">
                {_.get(studentProfile, 'district.name', '')}
              </p>
            </div>
            <div className="student-details__column">
              <p className="student-details__label student-details__label--spacing">
                大学
              </p>
              <p className="student-details__detail student-details__detail--spacing">
                {studentProfile.university}
              </p>

              <p className="student-details__label student-details__label--spacing">
                学部学科
              </p>
              <p className="student-details__detail student-details__detail--spacing">
                {studentProfile.major}
              </p>

              <p className="student-details__label student-details__label--spacing">
                文理区分
              </p>
              <p className="student-details__detail student-details__detail--spacing">
                {studentProfile.majorCategory}
              </p>

              <p className="student-details__label student-details__label--spacing">
                卒業見込み年
              </p>
              <p className="student-details__detail student-details__detail--spacing">
                {studentProfile.graduationYear}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

StudentProfile.propTypes = {
  isInPopup: PropTypes.bool,
  objectId: PropTypes.string,
  eventId: PropTypes.string,
  studentDetail: PropTypes.any,
  currentUser: PropTypes.object,
  eventInfo: PropTypes.object,
  gochiStatus: PropTypes.object,
  acceptGochiRequest: PropTypes.func
};
