import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './style.scss';

class StudentAvatar extends Component {
  render() {
    /**
     * @property {string} status Just focus on "follower" status, use for changing avatar style
     */
    const { studentInfo = {}, status } = this.props;
    const avatar = studentInfo.avatarImage ? studentInfo.avatarImage.thumbSmall : 'images/default-avatar.png';
    const yearsOld = moment().diff(moment(studentInfo.birthday), 'years');

    return (
      <div className={`student-management-cell-avatar ${status}`}>
        <img alt="" src={avatar} className="avatar" />
        {yearsOld < 20 && (<img alt="" className="bidden-beer" src="/images/bidden-beer.svg" />)}
      </div>
    );
  }
}

StudentAvatar.propTypes = {
  status: PropTypes.string,
  studentInfo: PropTypes.object
};

export default StudentAvatar;