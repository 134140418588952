import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button, Fade } from 'react-bootstrap';
import EventItem from '../../components/EventList/OfferEventItem';
import './styles.scss';

class CollapseElement extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { open, events, onOpen, name, title } = this.props;
    let collapseBtnClass = 'collapse-btn down';
    if (open && events.length !== 0) {
      collapseBtnClass = 'collapse-btn up';
    }
    return (
      <>
        <div className="event-group-title">
          <Button
            onClick={() => onOpen(!open, name)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            <span className="title">{title}</span>
            { events.length > 0 && <div className={collapseBtnClass} /> }
          </Button>
        </div>
        <div className="event-group cell-container">
          <Collapse in={open}>
            <Fade>
              <div id="example-collapse-text">
                {
                  events.map((item, index) => {
                    // eslint-disable-next-line max-len
                    return <EventItem role={this.props.role} key={index} data={item} hideInfo={false} />;
                  })
                }
              </div>
            </Fade>
          </Collapse>
        </div>
      </>
    );
  }
}

CollapseElement.propTypes = {
  open: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
  events: PropTypes.array.isRequired,
  onOpen: PropTypes.func.isRequired,
  role: PropTypes.string
};

export default CollapseElement;