import isEmail from 'sane-email-validation';
import _ from 'lodash';
import { isValidDate } from 'utilities/date';

function telephoneCheck(str) {
  var isphone = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(str);
  return !!isphone;
}

const validate = values => {
  const errors = {};  
  // fullname
  const fullname = values.get('fullname');
  if (!fullname) {
    errors.fullname = 'Required';
  } else if (fullname.length > 15) {
    errors.fullname = 'Max 15 character length';
  }
  // nameHiragana
  const nameHiragana = values.get('nameHiragana');
  if (!nameHiragana) {
    errors.nameHiragana = 'Required';
  } else if (nameHiragana.length > 15) {
    errors.nameHiragana = 'Max 15 character length';
  }
  // birthday
  const birthday = values.get('birthday');
  if (!birthday) {
    errors.birthday = 'Required';
  } else if (birthday.length !== 10 || !isValidDate(birthday)) {
    errors.birthday = 'Invalid date';
  }
  //phone
  const phone = values.get('phone');
  if (!phone) {
    errors.phone = 'Required';
  } else if (!telephoneCheck(phone)) {
    errors.phone = 'Invalid Phone Number';
  }
  // email
  const email = values.get('email');
  if (!email) {
    errors.email = 'Required';
  } else if (!isEmail(email)) {
    errors.email = 'Invalid email';
  }
  //
  if (!errors.hasOwnProperty('studentProfile')) {
    errors.studentProfile = {};
  }
  //
  // club
  const club = values.get('studentProfile') && values.get('studentProfile').get('club');
  if (!club) {
    if (!errors.hasOwnProperty('studentProfile')) {
      errors.studentProfile = {};
    }
    errors.studentProfile.club = 'Required';
  }
  // intro
  const introduction = values.get('studentProfile') && values.get('studentProfile').get('introduction');
  if (!introduction) {
    errors.studentProfile.introduction = 'Required';
  }
  
  //companytag
  const companyTags = values.get('studentProfile') && values.get('studentProfile').get('companyTags');
  if (!companyTags || _.isEmpty(companyTags)) {
    errors.studentProfile.companyTags = 'Required';
  }
  // university
  const university = values.get('studentProfile') && values.get('studentProfile').get('university');
  if (!university) {
    errors.studentProfile.university = 'Required';
  } else if (university.length > 40) {
    errors.studentProfile.university = 'Max 40 character length';
  }
  // major
  const major = values.get('studentProfile') && values.get('studentProfile').get('major');
  if (!major) {
    errors.studentProfile.major = 'Required';
  } else if (major.length > 40) {
    errors.studentProfile.major = 'Max 40 character length';
  }
  // majorCategory
  const majorCategory = values.get('studentProfile') && values.get('studentProfile').get('majorCategory');
  if (!majorCategory) {
    errors.studentProfile.majorCategory = 'Required';
  }
  // graduationYear
  const graduationYear = values.get('studentProfile') && values.get('studentProfile').get('graduationYear');
  if (!graduationYear) {
    errors.studentProfile.graduationYear = 'Required';
  }
  return errors;
};

export default validate;