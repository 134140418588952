import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import TagsField from 'components/Form/TagsField';
import ToggleGroupTag from 'components/Form/ToggleGroupTag';
import { Input, Select, Datepicker, Textarea } from 'components/Form';
import ProfilePhoto from 'components/Form/ProfilePhoto';
import validate from './validate';
import SelectWithExtraAndSearch from './../Inputs/SelectWithExtraAndSearch';
import './style.scss';

class EditMyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 1,
      startDate: new Date(props.initialValues.get('birthday')),
      imageHeight: 0,
      imageWidth: 0,
      showExtraUniversityField: false
    };
  }

  handleChooseuniversity = (value) => {
    this.setState({ showExtraUniversityField: value});
  }

  componentWillReceiveProps(nextProps) {
    let newState = {};
    if (nextProps.initialValues.get('birthday')) {
      newState.startDate = new Date(nextProps.initialValues.get('birthday'));
    }
    this.setState(newState);
  }

  render() {
    // console.log('------------> stage', this.state)
    const { handleSubmit, currentUser = {}, error } = this.props;
    const currentYear = new Date().getFullYear();
    const years = _.range(currentYear, currentYear + 11);
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit} className="form-horizontal student-edit-profile">
          { error && <p className="help-block text-danger text-center" style={{'fontSize': 20}}>{error}</p>}
          <div className="row">
            <div className="col-lg-6 col-xs-12">
              {/* <!-- Photos--> */}
              <div className="field-container photo-field">
                <Field
                  name="photos"
                  component={ProfilePhoto} />
              </div>
            </div>
            <div className="col-lg-6 col-xs-12" />
          </div>

          <div className="row">
            <div className="col-lg-6 col-xs-12">
              {/* <!-- Full Name--> */}
              <div className="field-container">
                <Field
                  name="fullname"
                  type="text"
                  label="氏名"
                  maxLength={15}
                  placeholder="氏名"
                  component={Input}
                  customForm={true}
                  required={true}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xs-12">
              {/* <!-- Hiragana Name--> */}
              <div className="field-container">
                <Field
                  name="nameHiragana"
                  type="text"
                  label="ふりがな"
                  maxLength={15}
                  placeholder="ふりがな"
                  component={Input}
                  customForm={true}
                  required={true}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-xs-12">
              {/* <!-- Birthday --> */}
              <div className="field-container">
                <Field
                  name="birthday"
                  label="生年月日"
                  dateFormat="YYYY-MM-DD"
                  component={Datepicker}
                  customForm={true}
                  required={true}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xs-12">
              {/* <!-- Gender --> */}
              <div className="field-container">
                <Field
                  name="gender"
                  label="性別"
                  component={Select}
                  customForm={true}
                  required={true}
                >
                  <option value="" disabled selected>選択してください</option>
                  <option value="MALE">男性</option>
                  <option value="FEMALE">女性</option>
                  <option value="NEITHER">どちらでもない</option>
                </Field>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-xs-12">
              {/* <!-- phone --> */}
              <div className="field-container">
                <Field
                  name="phone"
                  type="text"
                  label="電話番号"
                  placeholder="電話番号"
                  component={Input}
                  customForm={true}
                  required={true}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xs-12">
              {/* <!-- Email --> */}
              <div className="field-container">
                <Field
                  disabled={!!currentUser.email}
                  name="email"
                  type="email"
                  label="メールアドレス"
                  placeholder="メールアドレス"
                  component={Input}
                  customForm={true}
                  required={true}
                  withEditEmail
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-xs-12">
              {/* <!-- highSchool --> */}
              <div className="field-container">
                <Field
                  name="studentProfile[highSchool]"
                  type="text"
                  label="出身高校名(任意)"
                  placeholder="出身高校名(任意)"
                  component={Input}
                  customForm={true}
                  maxLength={30}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xs-12">
              {/* <!-- Club --> */}
              <div className="field-container">
                <Field
                  name="studentProfile[club]"
                  type="text"
                  label="部活・サークル"
                  placeholder="部活・サークル"
                  component={Input}
                  customForm={true}
                  required={true}
                  maxLength={50}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-xs-12">
              {/* <!-- Seminar --> */}
              <div className="field-container">
                <Field
                  name="studentProfile[seminar]"
                  type="text"
                  label="研究室・ゼミ"
                  placeholder="研究室・ゼミ"
                  component={Input}
                  customForm={true}
                  maxLength={30}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xs-12">
              {/* <!-- yourPolicy --> */}
              <div className="field-container">
                <Field
                  name="studentProfile[yourPolicy]"
                  type="text"
                  label="ポリシー・こだわり"
                  placeholder="ポリシー・こだわり"
                  component={Input}
                  customForm={true}
                  maxLength={40}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-xs-12">
              {/* <!-- portfolio --> */}
              <div className="field-container large-input">
                <Field
                  name="studentProfile[portfolio]"
                  type="text"
                  label="資格・過去の実績・制作物など"
                  placeholder="資格・過去の実績・制作物など"
                  component={Textarea}
                  customForm={true}
                  maxLength={300}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xs-12">
              {/* introduction */}
              <div className="field-container large-input">
                <Field
                  name="studentProfile[introduction]"
                  type="text"
                  label="自己 ＰＲ"
                  placeholder="自己 ＰＲ"
                  component={Textarea}
                  customForm={true}
                  required={true}
                  maxLength={1000}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-xs-12">
              {/* yourDream  */}
              <div className="field-container large-input">
                <Field
                  name="studentProfile[yourDream]"
                  type="text"
                  label="将来の目標"
                  placeholder="将来の目標"
                  component={Textarea}
                  customForm={true}
                  maxLength={300}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xs-12" />
          </div>

          <div className="row">
            <div className="col-lg-6 col-xs-12">
              {/* Industry  */}
              <div className="field-container">
                <Field
                  name="studentProfile[companyTags]"
                  type="text"
                  label="志望業界"
                  placeholder="志望業界"
                  customForm={true}
                  component={TagsField}
                  tag={this.props.companyTagsResources}
                  required={true}
                  maxSelectable={0}
                />
              </div>
            </div>
            <div className="col-lg-6 col-xs-12">
              {/* tag  */}
              <div className="field-container">
                <Field
                  name="tags"
                  type="text"
                  label="タグ"
                  placeholder="タグ"
                  customForm={true}
                  component={ToggleGroupTag}
                  tagGroup={this.props.tagsResource}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-xs-12">
              {/* <!-- University--> */}
              <div className="field-container suggestTextSpacing">
                <Field
                  name="studentProfile.universityId"
                  label="学校名"
                  maxLength={40}
                  placeholder="◯◯◯◯学院大学"
                  component={SelectWithExtraAndSearch}
                  customForm={true}
                  required={true}
                  items={this.props.availableUniversities}
                  showExtraField={this.handleChooseuniversity}
                />
              </div>
              { this.state.showExtraUniversityField && <div className="field-container">
                <Field
                  name="studentProfile.university"
                  type="text"
                  label="学校名"
                  maxLength={40}
                  placeholder="◯◯◯◯学院大学"
                  component={Input}
                  customForm={true}
                  required={true}
                />
              </div>}
            </div>
            <div className="col-lg-6 col-xs-12">
              {/* <!-- Major--> */}
              <div className="field-container">
                <Field
                  name="studentProfile.major"
                  type="text"
                  label="学部学科"
                  maxLength={40}
                  placeholder="△△学部ｘｘｘｘ学科"
                  component={Input}
                  customForm={true}
                  required={true}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-xs-12">
              {/* <!-- majorCategory --> */}
              <div className="field-container">
                <Field
                  name="studentProfile.majorCategory"
                  label="文理区分"
                  component={Select}
                  customForm={true}
                  required={true}
                >
                  <option disabled></option>
                  <option value="文系">文系</option>
                  <option value="理系">理系</option>
                  <option value="その他">その他</option>
                </Field>
              </div>
            </div>
            <div className="col-lg-6 col-xs-12">
              {/* <!-- graduation year --> */}
              <div className="field-container">
                <Field
                  name="studentProfile.graduationYear"
                  label="卒業見込み年"
                  component={Select}
                  customForm={true}
                  required={true}
                >
                  {[(<option disabled key='null'></option>)].concat(years.map((item) => {
                    return <option value={item} key={item}>{item}</option>;
                  }))}
                </Field>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-xs-12">
              {/* <!-- majorCategory --> */}
              <div className="field-container">
                <Field
                  name="studentProfile.district"
                  label="出身地"
                  placeholder="出身地を選択してください。"
                  customForm={true}
                  component={Select}>
                  <option value="" disabled={true} selected>
                  出身地を選択してください。
                  </option>
                  {this.props.availableDistrict.map((district) => (
                    <option value={district.value} key={district.value}>{district.name}</option>
                  ))}
                </Field>
              </div>
            </div>
          </div>

          {/* <!-- Button --> */}
          <div className="action-container">
            <Button className="btn-submit" disabled={!this.props.valid} name="submit" type="submit">
              保存
            </Button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

EditMyPage.propTypes = {
  currentUser: PropTypes.object,
  initialValues: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  error: PropTypes.any,
  tagsResource: PropTypes.array,
  companyTagsResources: PropTypes.array,
  availableUniversities: PropTypes.array,
  availableDistrict: PropTypes.array
};

export default reduxForm({
  form: 'StudentProfileForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(EditMyPage);
