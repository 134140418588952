export const SET_POPUP_LOGIN = 'SET_POPUP_LOGIN';
export const SET_ROLE_AND_OPEN = 'SET_ROLE_AND_OPEN';
export const SET_PRESIDENT_ROLE = 'SET_PRESIDENT_ROLE';
export const SET_STUDENT_ROLE = 'SET_STUDENT_ROLE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';

export function setRoleAndOpenPopup(role) {
  return {type: SET_ROLE_AND_OPEN, role};
}

export function setPopup(open) {
  return {type: SET_POPUP_LOGIN, open};
}

export function loginRequest(values, currentRole) {
  return {type: LOGIN_REQUEST, values, currentRole};
}

export function setPresidentRole() {
  return {type: SET_PRESIDENT_ROLE};
}

export function setStudentRole() {
  return {type: SET_STUDENT_ROLE};
}
