import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ElementRenderer from './ElementRenderer';

class Select extends Component {
  render() {

    return (
      <Fragment>
        {!this.props.customForm ? (
          <ElementRenderer
            {...this.props}
            render={({ input, children }) => (
              <select className="form-control" {...input} >
                {children}
              </select>
            )} />
        ) : (
          <div className={`custom-select-container ${!this.props.label && 'custom-input-no-label'} ${this.props.meta.error && 'error-form'}`}>
            {this.props.label ? (<span className="subtitle">{this.props.label}</span>) : null}
            <select className="form-control" {...this.props.input} >
              {this.props.children}
            </select>
          </div>
        )}
      </Fragment>
    );
  }
}

Select.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node,
  customForm: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.any
};

export default Select;