import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getCurrentAccount } from 'containers/Account/actions';
import { getResources } from 'containers/Resources/actions';
import {
  requestEventDetail,
  unsetEventDetail
} from 'containers/EventDetail/actions';
import {
  getStudentGochiRequest,
  acceptGochiRequest,
  getStudentAvailableSlotsRequest
} from 'containers/GochiEvent/actions';
import { setCommonPopup } from 'containers/Popup/actions';
import { requestUserInfo, removeUserInfo } from '../PresidentDetail2/action';
import StudentProfile from './StudentProfile';
import PopupSelectTemplate from 'containers/PopupSelectTemplate';
import './style.scss';

class MyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagResource: []
    };
    this.handleAcceptGochiRequest = this.handleAcceptGochiRequest.bind(this);
  }

  async componentDidUpdate(prevProps) {
    const { objectId } = this.props.match.params;
    const { objectId: prevObjectId } = prevProps.match.params;
    if (prevObjectId !== objectId) {
      this.props.getStudentGochiRequest(objectId);
    }
  }

  async componentWillMount() {
    const { objectId } = this.props.match.params;
    const searchParams = new URLSearchParams(this.props.location.search);
    const eventId = searchParams.get('eventId');

    if (objectId) {
      // eventId !== 'undefined': Student Management -> Follower Cell
      eventId &&
        eventId !== 'undefined' &&
        this.props.requestEventDetail(eventId);
      this.props.getStudentGochiRequest(objectId);
      this.props.getStudent(objectId);
    } else {
      await this.props.getCurrentAccount(this.props.token);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.tagResource) {
      this.props.getResources();
    } else {
      const tagResource = this.props.tagResource.map((value) => value.name);
      this.setState({
        tagResource: tagResource
      });
    }

    if (this.props.match.params.objectId !== nextProps.match.params.objectId) {
      this.props.getStudent(nextProps.match.params.objectId);
    }
  }
  componentWillUnmount() {
    this.props.unsetEventDetail();
    this.props.removeStudentDetail();
  }

  async handleAcceptGochiRequest() {
    const { objectId } = this.props.match.params;
    const {
      hasSubcrised,
      availableSlots,
      offerEventId
    } = await this.props.getStudentAvailableSlots(objectId);
    if (!hasSubcrised) {
      this.props.openConfirmPopup({
        title: 'この機能はオファー機能ご契約企業のみ利用可能です。',
        isAlert: true,
        btnConfirm: 'OK'
      });
      return;
    }
    if (!offerEventId && availableSlots === 0) {
      this.props.openConfirmPopup({
        title: `オファー枠を確認してください。\n選択した学生： 1\n残りオファー枠： ${availableSlots}`,
        isAlert: true,
        btnConfirm: 'OK'
      });
      return;
    }
    if (offerEventId) {
      this.props.openConfirmPopup({
        title: 'この学生とは既にオファー成立しているため\nオファー枠を消費せずこのまま\nメッセージ画面に遷移します。',
        isAlert: true,
        btnConfirm: 'OK',
        onConfirm: () => {
          this.props
            .acceptGochiRequest(
              _.get(this.props, 'gochiStatus.objectId', ''),
              objectId
            )
            .then(() => {
              this.props.history.push({
                pathname: `/messages/${offerEventId}`,
                state: {
                  eventId: offerEventId
                }
              });
            });
        }
      });
      return;
    }
    this.props.openConfirmPopup({
      title: `オファー枠を消費して学生とメッセージを開始することができます。\n残りオファー枠： ${availableSlots}\n実行しますか？`,
      btnConfirm: 'はい',
      btnCancel: 'いいえ',
      onConfirm: () => {
        this.props
          .acceptGochiRequest(
            _.get(this.props, 'gochiStatus.objectId', ''),
            objectId
          )
          .then(({ conversationId }) => {
            this.props.history.push({
              pathname: `/messages/${conversationId}`,
              state: {
                eventId: conversationId
              }
            });
          });
      }
    });
  }

  render() {
    const { objectId } = this.props.match.params;
    const searchParams = new URLSearchParams(this.props.location.search);
    // Always returns string type
    // So undefined is string "undefined"
    const eventId = searchParams.get('eventId');
    return (
      <>
        <StudentProfile
          currentUser={this.props.currentUser}
          studentDetail={this.props.studentDetail}
          objectId={objectId}
          eventInfo={this.props.eventInfo}
          eventId={eventId}
          gochiStatus={this.props.gochiStatus}
          acceptGochiRequest={this.handleAcceptGochiRequest}
        />
        <PopupSelectTemplate />
      </>
    );
  }
}

MyPage.propTypes = {
  token: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  gochiStatus: PropTypes.object,
  tagResource: PropTypes.array,
  getResources: PropTypes.func,
  getCurrentAccount: PropTypes.func,
  match: PropTypes.any,
  getStudent: PropTypes.func,
  studentDetail: PropTypes.any,
  location: PropTypes.any,
  requestEventDetail: PropTypes.func,
  unsetEventDetail: PropTypes.func,
  eventInfo: PropTypes.object,
  removeStudentDetail: PropTypes.func,
  getStudentGochiRequest: PropTypes.func,
  acceptGochiRequest: PropTypes.func,
  getStudentAvailableSlots: PropTypes.func,
  openAlertPopup: PropTypes.func,
  openConfirmPopup: PropTypes.func,
  history: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    token: state.get('account').get('token'),
    currentUser: state.get('account').get('data'),
    tagResource: state.get('resources').get('industries'),
    studentDetail: state.get('userDetail'),
    eventInfo: state.get('eventDetail').get('eventInfo'),
    gochiStatus: state.get('gochiEvent').get('currentStudent')
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCurrentAccount: (token) => dispatch(getCurrentAccount(token, 'mypage')),
  getResources: () => dispatch(getResources(['Industry'])),
  getStudent: (objectId) => dispatch(requestUserInfo(objectId)),
  requestEventDetail: (eventId) => dispatch(requestEventDetail(eventId)),
  unsetEventDetail: () => dispatch(unsetEventDetail()),
  removeStudentDetail: () => dispatch(removeUserInfo()),
  getStudentGochiRequest: (studentId) =>
    dispatch(getStudentGochiRequest(studentId)),
  acceptGochiRequest: (gochiId, studentId) =>
    dispatch(acceptGochiRequest(gochiId, studentId)),
  getStudentAvailableSlots: (studentId) =>
    dispatch(getStudentAvailableSlotsRequest({ studentId })),
  openAlertPopup: (show) => dispatch(setCommonPopup('success', show)),
  openConfirmPopup: (show) => dispatch(setCommonPopup('confirm', show))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(MyPage));
