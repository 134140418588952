import React, { Component, Fragment } from 'react';
import NotificationCell from '../NotificationCell';
import PropTypes from 'prop-types';
import _get from 'lodash/get'; 
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { getReviewStatusOfEvent } from '../../../Review/action';
import { connect } from 'react-redux';
import { openAbsenceFromNotification } from '../../../AbsenceWarning/action';
import { getOfferEventDetailRequest } from 'containers/OfferEventRequestModal/actions';

class NotificationList extends Component {
  render() {
    let { notificationList = [], location } = this.props;
    const data = this.props.data || {};
    const { displayNumber = 0 } = this.props;
    notificationList = displayNumber > 0 ? notificationList.slice(0, displayNumber) : notificationList;
    return (
      <Fragment>
        {
          notificationList.map((e) => {
            let link = '/';
            const linkArr = data[e] && data[e].linkTo && data[e].linkTo.split(':');
            const type = (linkArr && linkArr[0]) || '';
            switch (type) {
              case 'EVENT': {
                link = `/event-detail/${linkArr[1]}`;
                return (
                  <Link onClick={this.props.closePopOver} key={'notification-cell-' + e} to={link}>
                    <NotificationCell notificationInfo={(data[e]) || {}} />
                  </Link>
                );
              }

              case 'MESSAGE_EVENT': {
                link = `/messages/${linkArr[1]}`;
                return (
                  <Link onClick={this.props.closePopOver} key={'notification-cell-' + e} to={
                    {
                      pathname: link,
                      state: {
                        eventId: linkArr[1]
                      }
                    }
                  }>
                    <NotificationCell notificationInfo={(data[e]) || {}} />
                  </Link>
                );
              }

              case 'REVIEW': 
                return (
                  <NotificationCell
                    key={'notification-cell-' + e}
                    notificationInfo={(data[e]) || {}} 
                    handleClick={() => this.props.getReviewStatusOfEvent(linkArr[1], location.pathname)}
                  />
                );

              case 'PROFILE': {
                link = `/studentDetail/${linkArr[1]}`;
                return (
                  <Link onClick={this.props.closePopOver} key={'notification-cell-' + e} to={link}>
                    <NotificationCell notificationInfo={(data[e]) || {}} />
                  </Link>
                );
              }
              case 'REDIRECT':
                return (
                  <a 
                    href={_get(data[e], 'redirectUrl', '')}
                    rel="noopener noreferrer"
                    target="_blank"
                    key={'notification-cell-' + e}
                  >
                    <NotificationCell notificationInfo={(data[e]) || {}} />
                  </a>
                );
              case 'POPUP_ABSENCE_WARNING': {
                return <NotificationCell 
                  handleClick={() => { this.props.openAbsence(data[e]); }}
                  notificationInfo={(data[e]) || {}}
                />;
              }
              case 'REMIND_OFFER_EVENT_REQUEST':
              case 'REQUEST_OF_OFFER_EVENT': {
                return <NotificationCell 
                  handleClick={() => { 
                    this.props.getOfferEventDetail({ eventId: linkArr[1], reference: 'noti_list' }); 
                  }}
                  notificationInfo={(data[e]) || {}}
                  key={'notification-cell-' + e}
                />;
              }
              case 'STUDENT_ACCEPT_OFFER_EVENT': {
                link = `/messages/${linkArr[1]}`;
                return (
                  <Link onClick={this.props.closePopOver} key={'notification-cell-' + e} to={
                    {
                      pathname: link,
                      state: {
                        eventId: linkArr[1]
                      }
                    }
                  }>
                    <NotificationCell notificationInfo={(data[e]) || {}} />
                  </Link>
                );
              }
              default: return (<NotificationCell
                key={'notification-cell-' + e}
                notificationInfo={(data[e]) || {}} />);
            }
          })
        }
      </Fragment>
    );
  }
}

NotificationList.propTypes = {
  notificationList: PropTypes.array,
  data: PropTypes.object,
  displayNumber: PropTypes.number,
  getReviewStatusOfEvent: PropTypes.func,
  closePopOver: PropTypes.func,
  openAbsence: PropTypes.func,
  location: PropTypes.object,
  getOfferEventDetail: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  getReviewStatusOfEvent: (eventId, location) => dispatch(getReviewStatusOfEvent(eventId, location)),
  openAbsence: event => dispatch(openAbsenceFromNotification(event)),
  getOfferEventDetail: ({ eventId, reference }) => dispatch(getOfferEventDetailRequest({ eventId, reference }))
});

export default compose(
  connect(null, mapDispatchToProps), 
  withRouter
)(NotificationList);