import { fromJS } from 'immutable';
import { FETCH_CURRENT_ACCOUNT_SUCCESS, UNSET_ACCOUNT, SET_LOADING } from './actions';
import * as firebase from 'firebase/app'
import 'firebase/analytics';

const accountData = localStorage.getItem('account') ? JSON.parse(localStorage.getItem('account')) : null;
const initState = fromJS({
  token: accountData ? accountData.token : null,
  data: accountData ? accountData.data : null,
  loading: false,
  message: null
});

/**
 * example
 * @param state
 * @param action
 * @returns {Array}
 */
export default function account(state = initState, action) {
  switch (action.type) {
    case FETCH_CURRENT_ACCOUNT_SUCCESS: {
      localStorage.setItem('account', JSON.stringify({token: action.token, data: action.data}));
      const newData = fromJS(action.data);
      if (!newData.equals(state.get('data'))) {
        return state.set('data', newData).set('token', action.token).set('message', action.message).set('loading', false);
      } else {
        return state.set('token', action.token).set('message', action.message).set('loading', false);
      }
    }
    case UNSET_ACCOUNT: {
      localStorage.removeItem('reasonStorage');
      localStorage.removeItem('account');
      const unsetData = state.set('data', null);
      const unsetLoading = unsetData.set('loading', false);
      const setMesasge = unsetLoading.set('message', 'LOGOUT');
      firebase.analytics().setUserProperties({role: 'GUEST'});
      firebase.analytics().setUserId('guest')
      return setMesasge.set('token', null);
    }
    case SET_LOADING: {
      const unsetMessage = state.set('message', null);
      return unsetMessage.set('loading', action.value);
    }
    default:
      return state;
  }
}
