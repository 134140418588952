import { request } from 'utilities/Api';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { LOGIN_REQUEST } from './actions';
import { getLoginState } from './selectors';
import { getCurrentAccount } from './../Account/actions';
import { stopSubmit } from 'redux-form';
import { unsetCurrentAccount } from 'containers/Account/actions';
import { setCommonPopup } from 'containers/Popup/actions';
import webPush from '../../utilities/webPush';

function* handleLogin(action) {
  try {
    const state = yield select(getLoginState);
    const role = state.get('role');
    const values = JSON.parse(JSON.stringify(action.values).replace(/"\s+|\s+"/g, '"'));
    const data = yield call(request, 'login', values, 'GET');

    if (data.role === role) {
      let message = 'LOGIN';
      if (data.approvalStatus === 'WAITING') {
        message = 'UNAPPROVED_PRESIDENT_LOGIN';
      }
      
      try {
        const newSignUpStudent = JSON.parse(window.localStorage.getItem('newSignUpStudent')) || [];
        if (newSignUpStudent && newSignUpStudent.includes(data.objectId)) {
          message = 'NEW_STUDENT_LOGIN';
        }
      } catch (err) {
        window.localStorage.removeItem('newSignUpStudent');
      }
      
      if (data.status === 'ACTIVE' || (data.status === 'INACTIVE' && data.role === 'STUDENT')) {
        yield put(getCurrentAccount(data.sessionToken, message));
      }
      if (data.status === 'INACTIVE' && data.role === 'PRESIDENT') {
        yield put(setCommonPopup('success', {
          message: 'アカウントが停止されています。運営に連絡してください', 
          OKcallback: () => window.store.dispatch(unsetCurrentAccount()),
          onHideCallback: () => window.store.dispatch(unsetCurrentAccount()),
          btnLabel: 'はい'
        }));
      }
      if (data.status === 'DELETE_PROCESS') {
        yield put(stopSubmit('loginForm', { _error: '正しいメールアドレスを入力してください' }));
      }

      webPush.requestPermission();
    } else {
      yield put(stopSubmit('loginForm', { _error: 'メールアドレスもしくはパスワードが違います。' }));
    }
  } catch (e) {
    yield put(stopSubmit('loginForm', { _error: 'メールアドレスもしくはパスワードが違います。' }));
  }
}

export default function* watchLogin() {
  yield takeEvery(LOGIN_REQUEST, handleLogin);
}

 