import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { withRouter, Link } from 'react-router-dom';
import queryString from 'query-string';
import { getCurrentAccount } from 'containers/Account/actions';
import { studentSignup } from '../StudentSignup/actions';
import StudentSignupForm from 'components/StudentSignupForm';
import { reset } from 'redux-form';
import PropTypes from 'prop-types';
//UI
import './style.scss';

// const lineLoginAPI = 'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id='
//   + process.env.REACT_APP_LINE_CHANNEL_ID
//   + '&redirect_uri=' + process.env.REACT_APP_LINE_REDIRECT_URI
//   + '%2Fauth&state=12345abcde&scope=openid%20profile%20email';

// const appleLoginAPI = 'https://appleid.apple.com/auth/authorize?response_type=code%20id_token&client_id='
//   + process.env.REACT_APP_APPLE_SERVICE_ID
//   + '&redirect_uri=' + process.env.REACT_APP_APPLE_REDIRECT_URI
//   + '&scope=name%20email&response_mode=form_post&state=12345'

class SignUpForStudent extends Component {

  submitForm = (values) => {
    const requestData = values.set('email', values.get('username'));
    this.props.studentSignup({ role: 'STUDENT', ...requestData.toJS() });
  }

  componentWillUnmount() {
    this.props.resetForm();
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser) {
      const returnURN = queryString.parse(this.props.location.search) || {};

      this.props.history.push({
        pathname: returnURN.pathname || '/',
        search: ''
      });
    }
  }

  render() {
    return (
      <div id="signup-page-container">
        <div className="row">
          <div className="back-icon" onClick={this.props.history.goBack}>
            <img alt="" src="images/back.svg" /><span>戻る</span>
          </div>
          <section id="left-col-container" className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
            <div id="left-col">
              <h1>アカウント新規作成</h1>
              <StudentSignupForm onSubmit={this.submitForm} />
              <div className="divider-login">
                <hr />
                <span>OR</span>
              </div>
              {/* <div className='signup-authenticated'>
                <button
                  className='signup-line'
                  onClick={() => { window.location.href = lineLoginAPI }}
                >
                  <img src='images/line.svg' alt='LineIcon' />
                  &nbsp;&nbsp;LINEでアカウント作成
                </button>
                <div className='vertical-line'></div>
                <button
                  className='signup-apple'
                  onClick={() => { window.location.href = appleLoginAPI }}
                >
                  <img src='images/apple.svg' alt='AppleIcon' />
                  &nbsp;&nbsp;APPLEでアカウント作成
                </button>
              </div> */}
              <p className="subtext">
                <span>登録済みの方はこちら </span><Link to="/loginStudent" className="highlight">ログイン</Link>
              </p>
              <div className="policy"> 
                <a href="https://careertrip.jp/shachomeshi-agreement/" rel="noopener noreferrer" target="_blank">
                  利用規約
                </a>
                、
                <a href="https://www.unitedwill.jp/privacy/" rel="noopener noreferrer" target="_blank">
                  プライバシーポリシー及び個人情報の取り扱い
                </a>
              </div>
            </div>
          </section>
          <section id="right-col-container" style={{ backgroundImage: "url('images/background-login.png')" }} className="col-lg-5 hidden-md hidden-sm hidden-xs">
            <div id="right-col" >
              <img alt="" src="images/logo-login.svg" className="logo" />
              <p className="meshi-slogan">
                就活の場を<br />「デスク」から「テーブル」へ
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

SignUpForStudent.propTypes = {
  getCurrentAccount: PropTypes.func,
  history: PropTypes.object,
  studentSignup: PropTypes.func,
  resetForm: PropTypes.func
};

const mapStateToProps = state => {
  return {
    currentUser: state.get('account').get('data')
  };
};

const mapDispatchToProps = dispatch => ({
  getCurrentAccount: (token) => dispatch(getCurrentAccount(token)),
  studentSignup: (values) => dispatch(studentSignup(values)),
  resetForm: () => dispatch(reset('StudentSignupForm'))
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS
)(SignUpForStudent);