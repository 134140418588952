import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import { requestRelateEventList, removeRelateEventList } from './actions';
import { requestUserInfo, removeUserInfo } from 'containers/PresidentDetail2/action';
import EventCell from 'components/EventListSmall/EventListSmallCell';
import FollowButton from './FollowButton';
import {getSeoDataByUserIdRequest} from '../Seo/actions';
import { openModal } from 'containers/CustomModal/actions';
import { setRedirectURN } from 'containers/LinkWithRedirect/action';
import { setCommonPopup } from 'containers/Popup/actions';
import { getResources } from 'containers/Resources/actions';
import RequestGochiModal from 'containers/GochiEvent/RequestGochiModal';
import './style.scss';

class EventListOfPresident extends Component {
  loadMore = () => {
    if (Math.ceil(window.innerHeight + window.scrollY + 1) >= document.body.offsetHeight && this.props.hasMore) {
      const { match, location, page } = this.props;
      this.props.requestRelateEventList(_.get(match, 'params.presidentId', ''), location.state, page);
    }
  }

  componentDidMount() {
    const { currentUser, presidentDetail, match, location, page } = this.props;
    const { presidentId = '' } = match.params;
    this.props.getSeoDataByUserId(presidentId);
    this.props.getGochiResources();
    if (_.isEmpty(this.props.eventList)) {
      this.props.requestRelateEventList(presidentId, location.state, page);
    }

    if (_.isEmpty(presidentDetail) || !_.isEqual(presidentId, _.get(currentUser, 'objectId', ''))) {
      this.props.fetchPresidentDetail(presidentId);
    }
    window.addEventListener('scroll', this.loadMore);
  }

  componentWillUnmount() {
    this.props.removePresidentDetail();
    this.props.removeRelateEventList();
    window.removeEventListener('scroll', this.loadMore);
  }

  handleOpenGochiRequest = () => {
    const { currentUser, history, setRedirectURN } = this.props;
    if (currentUser) {
      this.props.openGochiRequestModal();
    } else {
      setRedirectURN(history.location.pathname);
      this.props.openPopupLogin();
    }
  }

  render() {
    const { seo, currentUser, presidentDetail, gochiResources, match } = this.props;

    return (
      <div id="event-list__president-page">
        <Helmet>
          <title>{seo.title}</title>
          <meta name="title" content={seo.title} />
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keyword} />
          <meta name="twitter:image" content={seo.image} />
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.image} />
        </Helmet>

        <div id="event-list-wrapper">
          <div id="event-list-title"><b>社長メシ一覧</b></div>
          {this.props.eventList.map((e, i) => 
            <Link key={i} to={`/event-detail/${e.objectId}`}>
              <EventCell eventInfo={e} type="relate" />
            </Link>
          )}
        </div>
        {(_.get(currentUser, 'role', '') !== 'PRESIDENT') && (
          <div id="set-follow-btn">
            <FollowButton 
              isFollowing={presidentDetail.isFollowing} 
              handleOpenGochiRequest={this.handleOpenGochiRequest}
            />

            <RequestGochiModal presidentId={_.get(match, 'params.presidentId', '')} gochiResources={gochiResources}/>
          </div>
        )}
      </div>
    );
  }
}

EventListOfPresident.propTypes = {
  seo: PropTypes.object,
  currentUser: PropTypes.object,
  presidentDetail: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  requestRelateEventList: PropTypes.func,
  removeRelateEventList: PropTypes.func,
  getSeoDataByUserId: PropTypes.func,
  fetchPresidentDetail: PropTypes.func,
  removePresidentDetail: PropTypes.func,
  setRedirectURN: PropTypes.func,
  openGochiRequestModal: PropTypes.func,
  openPopupLogin: PropTypes.func,
  sendGochiRequest: PropTypes.func,
  getGochiResources: PropTypes.func,
  hasMore: PropTypes.bool,
  page: PropTypes.number,
  eventList: PropTypes.array,
  gochiResources: PropTypes.array
};

const mapStateToProps = state => {
  return {
    seo: state.get('seo'),
    currentUser: state.get('account').get('data'),
    page: state.get('eventListOfPresident').get('page'),
    eventList: state.get('eventListOfPresident').get('list') || [],
    hasMore: state.get('eventListOfPresident').get('hasMore'),
    presidentDetail: state.get('userDetail'),
    gochiResources: state.get('resources').get('gochioption')
  };
};

const mapDispatchToProps = dispatch => ({
  getSeoDataByUserId: (objectId) => dispatch(getSeoDataByUserIdRequest(objectId)),
  requestRelateEventList: (presidentId, eventId, page) => dispatch(requestRelateEventList(presidentId, eventId, page)),
  removeRelateEventList: () => dispatch(removeRelateEventList()),
  fetchPresidentDetail: (presidentId) => dispatch(requestUserInfo(presidentId)),
  removePresidentDetail: () => dispatch(removeUserInfo()),
  setRedirectURN: link => dispatch(setRedirectURN(link)),
  openPopupLogin: () => dispatch(setCommonPopup('PopupLogin', true)),
  openGochiRequestModal: () => dispatch(openModal({ key: 'requestGochi' })),
  getGochiResources: () => dispatch(getResources(['GochiOption']))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(EventListOfPresident));
