export const STUDENT_COMPLETE_PROFILE = 'STUDENT_COMPLETE_PROFILE';
export const STUDENT_COMPLETE_PROFILE_SUCCESS = 'STUDENT_COMPLETE_PROFILE_SUCCESS';
export const STUDENT_COMPLETE_PROFILE_FAIL = 'STUDENT_COMPLETE_PROFILE_FAIL';

export function studentCompleteProfile(values, sessionToken, message) {
  return {
    type: STUDENT_COMPLETE_PROFILE,
    values,
    sessionToken,
    message,
    meta: {
      thunk: true
    }
  };
}

export function studentCompleteProfileSuccess(meta, data = {}) {
  return {
    type: STUDENT_COMPLETE_PROFILE_SUCCESS,
    data,
    meta
  };
}

export function studentCompleteProfileFail(meta, data = {}) {
  return {
    type: STUDENT_COMPLETE_PROFILE_FAIL,
    data,
    meta,
    error: true
  };
}