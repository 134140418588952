import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { getValueByKey } from 'containers/Popup/selectors';
import PropTypes from 'prop-types';
import { setCommonPopup } from 'containers/Popup/actions';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { confirmAttendance } from './actions';

class Popup24hConfirm extends Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.closePopup24hConfirm} className="msg-popup" id="24h-popup">
        <div className="popup-close" onClick={this.props.closePopup24hConfirm} />
        <div className="title text-center">出席確認（前日）</div>
        <div className="msg text-center">
          明日はいよいよ社長メシですね！
          <br />
          社長への質問など、いろいろ
          <br />
          考えてみましょう！
        </div>
        <div className="popup-btn" onClick={() => this.props.confirmAttendance(this.props.eventId)}>送信</div>
      </Modal>
    );
  }
}

Popup24hConfirm.propTypes = {
  show: PropTypes.bool,
  closePopup24hConfirm: PropTypes.func,
  confirmAttendance: PropTypes.func,
  eventId: PropTypes.string
};

const mapStateToProps = state => {
  return {
    show: getValueByKey(state, '24hConfirm')
  };
};

const mapDispatchToProps = dispatch => ({
  closePopup24hConfirm: () => dispatch(setCommonPopup('24hConfirm', false)),
  confirmAttendance: (eventId) => dispatch(confirmAttendance(eventId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(Popup24hConfirm));