import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './style.scss';

class StudentInfo extends Component {
  render() {
    const { student = {}, size, status } = this.props;
    const { studentProfile = {} } = student;
    const yearsOld = moment().diff(moment(student.birthday), 'years');
    const avatarImage = student.avatarImage ? 
      student.avatarImage.thumbSmall : 
      '/images/avatar-default-complete.svg';

    return (
      <div className="student-info">
        <div className={`student-avatar ${size}`}>
          <img alt="" className="avatar" src={avatarImage}/>
          {yearsOld < 20 && <img alt="" className="bidden-beer" src="/images/bidden-beer.svg" />}
        </div>
        {status === 'joined' 
          ? (<div>{student.displayName}</div>) 
          : (<div className="year">
            {student.gender === 'MALE' ? '男性' : student.gender === 'FEMALE' ? '女性' : 'どちらでもない'}
            ({studentProfile.university}, {student.graduationYear || studentProfile.graduationYear}年卒)</div>)
        }
      </div>
    );
  }
}

StudentInfo.propTypes = {
  size: PropTypes.string,
  status: PropTypes.string,
  student: PropTypes.object
};

export default StudentInfo;
