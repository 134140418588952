import React, { Component } from 'react';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PropTypes from 'prop-types';

class TriggerLogout extends Component {

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentUser = this.props.currentUser;
    const nextUser = nextProps.currentUser;
    if (currentUser.token !== null && nextUser.token === null) {
      this.props.afterLogout();
    }
  }

  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

TriggerLogout.propTypes = {
  afterLogout: PropTypes.func,
  currentUser: PropTypes.any,
  children: PropTypes.any
};

TriggerLogout.defaultProps = {
  afterLogout: () => null
};

const mapStateToProps = state => {
  return {
    currentUser: state.get('account')
  };
};

export default connect(
  mapStateToProps
)(withImmutablePropsToJS(TriggerLogout));
