import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { STUDENT_SURVEY_REQUEST } from './actions';
import { setCommonPopup } from 'containers/Popup/actions';
import { stopSubmit } from 'redux-form';

function* handleStudentSurvey(action) {
  try {
    yield call(request, 'functions/studentAnswerHowQuestion', action.values, 'POST');
    yield put(setCommonPopup('studentSurvey', false));

    try {
      let newSignUpStudent = JSON.parse(window.localStorage.getItem('newSignUpStudent')) || [];
      const currentUser = window.localStorage.getItem('account').data;
  
      newSignUpStudent = newSignUpStudent.filter((element) => element !== currentUser.objectId);
  
      window.localStorage.setItem('newSignUpStudent', JSON.stringify(newSignUpStudent));
    } catch (err) {
      window.localStorage.removeItem('newSignUpStudent');
    }

  } catch (e) {
    yield put(stopSubmit('StudentSurvey', { _error: e.error }));
  }
}

export function* watchStudentSurvey() {
  yield takeEvery(STUDENT_SURVEY_REQUEST, handleStudentSurvey);
}

