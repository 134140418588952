import { request } from 'utilities/Api';
import { put, takeEvery, call } from 'redux-saga/effects';
import { STUDENT_SIGNUP_REQUEST } from './actions';
import { getCurrentAccount } from './../Account/actions';
import { stopSubmit } from 'redux-form';

function* handleStudentSignUp(action) {
  try {
    const data = yield call(request, 'users', action.values, 'POST');
    yield put(getCurrentAccount(data.sessionToken, 'STUDENT_SIGNUP'));
  } catch (e) {
    if (e.code === 202) {
      e.error = 'このメールアドレスはすでに登録されています';
    }
    yield put(stopSubmit('StudentSignupForm', { _error: e.error }));
  }
}

export default function* watchStudentSignUp() {
  yield takeEvery(STUDENT_SIGNUP_REQUEST, handleStudentSignUp);
}

 