import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { setCommonPopup } from 'containers/Popup/actions';
import { getValueByKey } from 'containers/Popup/selectors';
import Button from 'components/Button';

import './styles.scss';

const PopupConfirm = ({ show, closeConfirmPopup }) => {
  const onConfirm = () => {
    closeConfirmPopup();
    _.result(show, 'onConfirm', null);
  };

  const onHide = () => {
    closeConfirmPopup();
    _.result(show, 'onHide', null);
  };

  return (
    <Modal show={!!show} backdrop="static" onExited={onHide} id="confirm-popup">
      {_.get(show, 'closeButton', false) && (
        <div className="close-modal" onClick={closeConfirmPopup}>
          <img alt="" src="./images/Quit.svg" />
        </div>
      )}
      {show.title && (
        <div className="confirm-title bold">{show.title}</div>
      )}
      {show.message && (
        <div className="confirm-message">{show.message}</div>
      )}
      <Button onClick={onConfirm} className='confirm-btn'>{show.btnConfirm || 'OK'}</Button>
      {!show.isAlert && (
        <div onClick={onHide} className='cancel-btn'>{show.btnCancel || 'Cancel'}</div>
      )}
    </Modal>
  );
};

PopupConfirm.propTypes = {
  show: PropTypes.any,
  closeConfirmPopup: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    show: getValueByKey(state, 'confirm')
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeConfirmPopup: () => dispatch(setCommonPopup('confirm', false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withImmutablePropsToJS(PopupConfirm));