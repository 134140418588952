import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import PrivateLink from './../PrivateLink';
import { setCommonPopup } from './../Popup/actions';
import { unsetCurrentAccount } from './../Account/actions';
import LinkWithRedirect from 'containers/LinkWithRedirect';
import { setMenu } from 'containers/Header/actions';
import { fetchUnreadNotification } from 'containers/Notification/actions';
import CloseMenuButton from 'components/MenuRight/CloseMenuButton';
class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.action(0);
  }
  componentDidMount() {
    //this.props.fetchUnreadNotificationNumber();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.mainMenu === 1) {
      document.body.classList.add('main-menu-open');
    } else {
      document.body.classList.remove('main-menu-open');
    }
  }
  menu() {
    const {
      currentUser,
      openLogout,
      unreadNotiNumber,
      unreadMessageNumber,
      controlNotificationMenu,
      unreadMessageFromOperator
    } = this.props;
    let newMessageFromOperator = 0;
    if (unreadMessageFromOperator > 0) {
      newMessageFromOperator = 1;
    }

    if (!currentUser) {
      return (
        <React.Fragment>
          <li className="menu-item">
            <LinkWithRedirect to="/loginStudent" onClick={this.handleClick}>
              学生ログイン
            </LinkWithRedirect>
          </li>
          <li className="menu-item">
            <LinkWithRedirect /*to={'https://shachomeshi.com/company/'}*/ to="/loginPresident" onClick={this.handleClick}>
              社長ログイン
            </LinkWithRedirect>
          </li>
          <li className="menu-item">
            <LinkWithRedirect to="/signUpStudent" onClick={this.handleClick}>
              学生サインアップ
            </LinkWithRedirect>
          </li>
          <li className="menu-item">
            <LinkWithRedirect /*to={'https://shachomeshi.com/company/'}*/ to="/presidentTutorial" onClick={this.handleClick}>
              社長サインアップ
            </LinkWithRedirect>
          </li>
        </React.Fragment>
      );
    }

    if (currentUser.role === 'STUDENT') {
      return (
        <React.Fragment>
          <li className="menu-item">
            <Link to="/my-page" onClick={this.handleClick}>
              プロフィール
            </Link>
          </li>
          {/* <li className="menu-item" onClick={this.handleClick}> */}
          <li className="menu-item" onClick={this.handleClick}>
            <Link to="/event-history">
              食事会一覧
            </Link>
          </li>

          <li className="menu-item m-0 pl-4" onClick={this.handleClick}>
            <Link to="/offer-management">
              オファー
            </Link>
          </li>

          <li className="menu-item m-0 pl-4" onClick={this.handleClick}>
            <Link to="/gochi-management">
              ごちリクエスト
            </Link>
          </li>

          <li className="menu-item menu-notification" onClick={() => controlNotificationMenu(1)}>
            <span>
              お知らせ
            </span>
            {unreadNotiNumber > 0 &&
              <span className="noti-number">{unreadNotiNumber}</span>
            }
          </li>
          <li className="menu-item m-0 pl-4">
            <PrivateLink link="/messages" handleAction={this.handleClick}>
              <div className="menu-item__messages">
                <div>メッセージ</div>
                {(unreadMessageNumber + newMessageFromOperator) > 0 &&
                  <div className="menu-item__messages__number text-center">{(unreadMessageNumber + newMessageFromOperator)}</div>
                }
              </div>
            </PrivateLink>
          </li>
          <li className="menu-item">
            <a href="https://careertrip.jp/shachomeshi-guide/faq/" rel="noopener noreferrer" target="_blank" onClick={this.handleClick}>
              よくあるご質問
            </a>
          </li>
          <li className="menu-item border-none" onClick={() => { this.handleClick(); openLogout(); }}>
            <span>
              ログアウト
            </span>
          </li>
        </React.Fragment>
      );
    }

    if (currentUser.role === 'PRESIDENT') {
      return (
        <React.Fragment>
          <li className="menu-item">
            <PrivateLink link="/my-page" handleAction={this.handleClick}>
              プロフィール
            </PrivateLink>
          </li>
          <li className="menu-item">
            <PrivateLink link="/management-event/wanted" handleAction={this.handleClick}>
              イベント管理
            </PrivateLink>
          </li>
          <li className="menu-item m-0 pl-4">
            <PrivateLink link="/management-student" handleAction={this.handleClick}>
              ユーザー管理
            </PrivateLink>
          </li>
          <li className="menu-item menu-notification">
            <PrivateLink handleAction={() => controlNotificationMenu(1)}>
              お知らせ
            </PrivateLink>
            {unreadNotiNumber > 0 &&
              <span className="noti-number">{unreadNotiNumber}</span>
            }
          </li>
          <li className="menu-item m-0 pl-4">
            <PrivateLink link="/messages" handleAction={this.handleClick}>
              <div className="menu-item__messages">
                <div>メッセージ</div>
                {unreadMessageNumber > 0 &&
                  <div className="menu-item__messages__number text-center">{unreadMessageNumber}</div>
                }
              </div>
            </PrivateLink>
          </li>
          <li className="menu-item border-none" onClick={() => { this.handleClick(); openLogout(); }}>
            <span>
              ログアウト
            </span>
          </li>
        </React.Fragment>
      );
    }
  }

  render() {
    let avatar = '/images/default-profile.png';
    if (this.props.currentUser && this.props.currentUser.avatarImage) {
      avatar = this.props.currentUser.avatarImage.thumbSmall;
    }
    return (
      <React.Fragment>
        <div className="menu-container-content">
          <CloseMenuButton />
          <ul className="menu-list">
            <li className="menu-item m-0 p-3">
              <div id="logo-menu">
                {this.props.currentUser ? (
                  <Link to="/my-page" onClick={this.handleClick}>
                    <img src={avatar} alt="avatar" />
                    <div id="name">
                      <div>{this.props.currentUser.displayName}</div>
                      <div>@{this.props.currentUser.objectId}</div>
                    </div>
                  </Link>
                ) : (
                  <div id="text-logo">社長メシ</div>
                )}
              </div>
            </li>
            {this.menu()}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

MainMenu.propTypes = {
  action: PropTypes.func,
  currentUser: PropTypes.any,
  mainMenu: PropTypes.any,
  openLogout: PropTypes.func,
  logout: PropTypes.func,
  history: PropTypes.any,
  setRedirectURN: PropTypes.func,
  controlNotificationMenu: PropTypes.func,
  unreadMessageFromOperator: PropTypes.number,
  unreadNotiNumber: PropTypes.number,
  unreadMessageNumber: PropTypes.number
};

const mapStateToProps = state => {
  return {
    currentUser: state.get('account').get('data'),
    unreadNotiNumber: state.get('getNoticeList').get('number'),
    mainMenu: state.get('menu').get('mainMenu'),
    unreadMessageNumber: state.get('message').get('badgeNumber'),
    unreadMessageFromOperator: state.get('firebaseListener').get('unreadMessages')
  };
};

const mapDispatchToProps = (dispatch) => ({
  openLogout: () => dispatch(setCommonPopup('logout', true)),
  logout: () => dispatch(unsetCurrentAccount()),
  controlNotificationMenu: (open) => dispatch(setMenu('notificationMenu', open)),
  fetchUnreadNotificationNumber: () => dispatch(fetchUnreadNotification())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withImmutablePropsToJS
)(MainMenu);
