import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { loginRequest, setPresidentRole } from './actions';
import { reduxForm, Field } from 'redux-form/immutable';
import { withRouter, Link} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import validate from './validate';
import TextField from '../../components/Inputs/TextField';
//Spinner
import Loading from '../../components/Loading';
import './style.scss';

// const lineLoginAPI = 'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id='
//   + process.env.REACT_APP_LINE_CHANNEL_ID
//   + '&redirect_uri=' + process.env.REACT_APP_LINE_REDIRECT_URI
//   + '%2Fauth&state=12345abcde&scope=openid%20profile%20email';

class PresidentLogin extends Component {

  state = {
    loading: false
  }

  componentDidMount = () => {
    //Set role to PRESIDENT
    this.props.setRole();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser) {
      const returnURN = queryString.parse(this.props.location.search) || {};

      this.props.history.push({
        pathname: returnURN.pathname || '/',
        search: ''
      });
    }

    if (nextProps.error) {
      this.setState({loading: false});
    }
  }

  render() {
    return (
      <div id="login-page-container">
        { this.props.error === 'undefined' && this.state.loading ? <Loading /> : null }
        <div className="row">
          <div className="back-icon" onClick={this.props.history.goBack}>
            <img alt="" src="images/back.svg" /><span>戻る</span>
          </div>
          <section id="left-col-container" className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
            <div id="left-col">
              <h1>社長側ログイン</h1>
              <form onSubmit={this.props.handleSubmit}>
                <div className="field-container">
                  <Field
                    component={TextField}
                    className="input-form"
                    name="email"
                    placeholder="メールアドレス"
                    type="email"
                    autocomplete="off"
                  />
                </div>
                <div className="field-container">
                  <Field
                    component={TextField}
                    className="input-form"
                    name="password"
                    placeholder="パスワード"
                    type="password"
                    showEye={true}
                  />
                </div>
                {this.props.error ?
                  (<div className="alert alert-danger">{this.props.error}</div>)
                  : null
                }
                <Button onClick={() => this.setState({loading: true})} type="submit" disabled={!this.props.valid} className={`btn-login ${this.props.valid && 'btn-active'}`}>ログイン</Button>
              </form>
              <Link to='forgot-password'>
                <p className="forget-password">
                  パスワードを忘れた方はこちら
                </p>
              </Link>
            </div>
          </section>
          <section id="right-col-container" style={{ backgroundImage: "url('images/background-login.png')" }} className="col-lg-5 hidden-md hidden-sm hidden-xs">
            <div id="right-col" >
              <img alt="" src="images/logo-login.svg" className="logo" />
              <p className="meshi-slogan">
                就活の場を<br />「デスク」から「テーブル」へ
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

PresidentLogin.propTypes = {
  error: PropTypes.any,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  popup: PropTypes.bool,
  controlUserMenu: PropTypes.func,
  reset: PropTypes.func,
  setPopup: PropTypes.func,
  role: PropTypes.string,
  setRole: PropTypes.func
};

const mapStateToProps = state => {
  return {
    role: state.get('login').get('role'),
    currentUser: state.get('account').get('data')
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: (values) => dispatch(loginRequest(values.toJS())),
  setRole: () => dispatch(setPresidentRole())
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'loginForm', validate }),
  withImmutablePropsToJS
)(PresidentLogin);